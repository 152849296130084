import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';

export default function Message({ message, type = 'error', setError }) {
  const [isVisible, setIsVisible] = useState(true);

  const deleteMessage = () => {
    setIsVisible(false);
    setError && setError('');
  };

  return (
    <div
      id="errorMsg"
      className={`${
        !isVisible && 'hidden'
      } flex max-w-max mx-auto my-4 bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden`}
    >
      {window.scroll(0, 0)}
      <div
        className={`flex justify-center items-center w-3 ${
          (type === 'error' && 'bg-red-500') ||
          (type === 'success' && 'bg-green-500') ||
          (type === 'warning' && 'bg-yellow-500') ||
          'bg-blue-600'
        }`}
      />
      <div className="-mx-3 py-2 px-3">
        <div className="mx-3">
          <span
            className={`${
              type === 'error' && 'text-red-500'
            } dark:text-red-400 font-semibold`}
          >
            {type === 'error' && 'Erreur : '}
          </span>
          <span className="text-gray-600 dark:text-gray-200 text-sm">
            {message}
          </span>
        </div>
      </div>
      {setError && (
        <div className="self-center mr-1" onClick={deleteMessage}>
          <ClearIcon className="cursor-pointer" />
        </div>
      )}
    </div>
  );
}
