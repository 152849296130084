import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Appelle la route /login pour connecter un utilisateur à son compte
 * @param {Object} data L'username + le mot de passe
 * @param {String} appToken La clé d'API
 * @returns le token d'authentification | Un message d'erreur si mauvais identifiants
 */
export async function login(data) {
  const post = await fetchWithTimeout(`${API_URL}users/login/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
  return post;
}

/**
 * Retourne tous les utilisateurs du site
 * @param {String} token Le token d'admin de l'user connecté
 * @param {String} username l'username de l'user recherché
 * @param {Number} offset offset de pagination
 * @param {Number} limit limit de pagination
 * @returns array de tous les users
 */
export async function getUsers(
  token,
  { offset = 0, limit = 20, username = '', status = [] }
) {
  const users = await fetchWithTimeout(
    `${API_URL}users/?limit=${limit}&offset=${offset}${
      username.length > 0 ? `&username=${username}` : ''
    }${status.length > 0 ? `&status=${status.join('&status=')}` : ''}`,
    {
      headers: { 'Content-Type': 'application/json', 'auth-token': token },
    }
  );
  return users;
}

/**
 * Retourne un utilisateur avec son username
 * @param {String} username Le nom de l'utilisateur
 * @param {String} token Le token d'admin de l'user connecté
 * @returns Object l'utilisateur demandé
 */
export async function getUser(username, token) {
  const user = await fetchWithTimeout(`${API_URL}users/${username}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return user;
}

/**
 * Crée un nouvel utilisateur
 * @param {Object} body Les informations du nouvel utilisateur
 * @param {String} token Le token d'authentification de l'admin connecté
 * @returns Les informations du nouvel utilisateur | Un message d'erreur si pas autorisé
 */
export async function createUser(body, token) {
  const post = await fetchWithTimeout(`${API_URL}users/`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return post;
}

/**
 * Modifie l'utilisateur concerné
 * @param {String} username Le nom de l'utilisateur à modifier
 * @param {Object} body Les nouvelles informations de l'utilisateur
 * @param {String} token Le token d'authentification
 * @returns Les informations de l'utilisateur concerné | Un message d'erreur si pas autorisé
 */
export async function editUser(username, body, token) {
  const patch = await fetchWithTimeout(`${API_URL}users/${username}`, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return patch;
}

/**
 * Supprime un utilisateur
 * @param {String} username Le nom de l'utilisateur à supprimer
 * @param {String} authToken Le token d'authentification
 * @returns Rien | Un message d'erreur si pas autorisé
 */
export async function deleteUser(username, token) {
  const user = await fetchWithTimeout(`${API_URL}users/${username}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return user;
}
