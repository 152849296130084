import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Appelle la route /images/put pour récupérer une URL présignée PUT de MinIO
 * @param {String} token Le token d'authentification
 * @param {String} name Le nom de l'image (Optionnel, généré par le back si non précisé)
 * @returns {Promise<{url: string, name: string}>} L'URL pour upload et le nom du fichier
 */
export async function getPutPSU(token, name = '') {
  let url = `${API_URL}images/put`;
  if (name.length !== 0) url += `?name=${name}`;
  return await fetchWithTimeout(url, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Appelle la route /images/get/:name pour récupérer une URL présignée GET de MinIO
 * @param {String} name le nom du fichier à récupérer
 * @param {String} token Le token d'authentification
 * @returns {Promise<{url: string}>} L'URL pour récupérer la photo
 */
export async function getGetPSU(name, token) {
  return await fetchWithTimeout(`${API_URL}images/get/${name}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Appelle la route DELETE /images/:name pour supprimer une image de MinIO
 * @param {String} name le nom du fichier à supprimer
 * @param {String} token Le token d'authentification
 */
export async function deleteImage(name, token) {
  return await fetchWithTimeout(`${API_URL}images/${name}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Appelle la route de MinIO pour uploader une image
 * @param {String} url L'adresse à laquelle upload le fichier
 * @param {File} file Le fichier à upload
 * @param {String} contentType Le type de contenu du fichier
 */
export async function putMinio(url, file, contentType) {
  return fetchWithTimeout(url, {
    method: 'PUT',
    body: file,
    headers: { 'Content-Type': contentType },
  });
}

/**
 * Appelle la route de MinIO pour récupérer une image
 * @param {String} url L'adresse à laquelle télécharger le fichier
 * @returns {File} l'image demandée
 */
export async function getMinio(url) {
  return await fetchWithTimeout(url);
}
