import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Message from '../components/Message';
import { Context } from '../components/Store';
import { deleteUser, getUsers } from '../functions/user';
import { deleteVehicle, getVehicles } from '../functions/vehicle';
import { mapUserStatus } from '../utils/constants';

export default function Gestion() {
  const [state] = useContext(Context);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchVehicles = useCallback(async () => {
    setIsLoading(true);
    const fetchedVehicles = await getVehicles(state.token);
    setIsLoading(false);
    if (fetchedVehicles.error) return setError(fetchedVehicles.error);
    return setVehicles(fetchedVehicles.data);
  }, [state.token]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    const fetchedUsers = await getUsers(state.token, { limit: 100 });
    setIsLoading(false);
    if (fetchedUsers.error) return setError(fetchedUsers.error);
    return setUsers(
      fetchedUsers?.data.sort((a, b) => b.status - a.status) || []
    );
  }, [state.token]);

  useEffect(() => {
    fetchUsers();
    fetchVehicles();
    if (localStorage.getItem('message')) {
      setInfo(localStorage.getItem('message'));
      localStorage.removeItem('message');
    }
  }, [fetchUsers, fetchVehicles]);

  const deleteFromList = async (type, id, nom = id) => {
    let deleted;
    let res;
    if (type === 'user') {
      res = window.prompt(
        `Confirmez la suppression de l'utilisateur ${nom} en renseignant son nom d'utilisateur ci-dessous. Cette action est définitive.`
      );
      if (res === nom) {
        setIsLoading(true);
        deleted = await deleteUser(id, state.token);
        setIsLoading(false);
        if (deleted.error) return setError(deleted.error);
        fetchUsers();
        setError('');
        setInfo(`L'utilisateur ${id} a bien été supprimé.`);
      } else if (typeof res === 'string' || res > 0) {
        setError("Le nom d'utilisateur ne correspond pas.");
      }
    } else {
      res = window.prompt(
        `Confirmez la suppression du véhicule ${nom} en renseignant son nom ci-dessous. Cette action est définitive.`
      );
      if (res === nom) {
        setIsLoading(true);
        deleted = await deleteVehicle(id, state.token);
        setIsLoading(false);
        if (deleted.error) return setError(deleted.error);
        fetchVehicles();
        setError('');
        setInfo('Le véhicule a bien été supprimé.');
      } else if (typeof res === 'string' || res > 0) {
        setError('Le nom du véhicule ne correspond pas.');
      }
    }
  };

  document.title = 'Utilisateurs et Véhicules';

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1 className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8">
          Gestion des utilisateurs et véhicules
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        {isLoading ? (
          'Chargement...'
        ) : (
          <div>
            <div className="bg-stbSecond rounded">
              <div className="bg-gray-800 text-white rounded-t">
                <div className="flex items-center justify-between py-2 font-bold">
                  <div className="flex w-1/3 ml-2" />
                  <div className="flex w-1/3 justify-center">
                    <h2>Liste des utilisateurs</h2>
                  </div>
                  <div className="flex w-1/3 mr-2 justify-end">
                    <Link
                      to="/users/new"
                      className="mr-8 border py-1 px-2 rounded border-white hover:bg-stbMain "
                    >
                      Nouvel utilisateur
                    </Link>
                  </div>
                </div>
              </div>
              <table className="w-full">
                <thead className="bg-stbMain text-white h-10 border-b-2 border-gray-800">
                  <tr>
                    <th>Nom d'utilisateur</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Statut</th>
                    <th>Interventions liées</th>
                    <th className="px-1">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                    users.map((user, i) => {
                      return (
                        <tr
                          key={user._id}
                          className={`hover:bg-stbMain hover:text-white ${
                            i % 2 === 0 && 'bg-gray-400'
                          }`}
                        >
                          <td>{user.username}</td>
                          <td>{user.prenom}</td>
                          <td>{user.nom}</td>
                          <td>{mapUserStatus(user.status)}</td>
                          <td>
                            {user.status === 1 && (
                              <Link to={`/fiches/user/${user._id}`}>
                                <VisibilityIcon alt="Voir les interventions liées" />
                              </Link>
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/users/${user.username}`}
                              className="hover:text-stbSecond"
                            >
                              <EditIcon />
                            </Link>
                            <DeleteIcon
                              className="hover:text-stbSecond cursor-pointer"
                              onClick={() => {
                                deleteFromList('user', user.username);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <Message
                          message={"Aucun utilisateur n'a été trouvé"}
                          type="warning"
                          setError={setWarning}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="bg-gray-800 text-white justify-start flex rounded-b pl-2 py-2" />
            </div>
            <div className="bg-stbSecond rounded mt-8">
              <div className="bg-gray-800 text-white rounded-t">
                <div className="flex items-center justify-between py-2 font-bold">
                  <div className="flex w-1/3 ml-2" />
                  <div className="flex w-1/3 justify-center">
                    <h2>Liste des véhicules</h2>
                  </div>
                  <div className="flex w-1/3 mr-2 justify-end">
                    <Link
                      to="/vehicles/new"
                      className="mr-8 border py-1 px-2 rounded border-white hover:bg-stbMain "
                    >
                      Nouveau véhicule
                    </Link>
                  </div>
                </div>
              </div>
              <table className="w-full">
                <thead className="bg-stbMain text-white h-10 border-b-2 border-gray-800">
                  <tr>
                    <th>Véhicule</th>
                    <th>Kilométrage</th>
                    <th className="px-1">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicles.length > 0 ? (
                    vehicles.map((vehicle, i) => {
                      return (
                        <tr
                          key={vehicle._id}
                          className={`hover:bg-stbMain hover:text-white ${
                            i % 2 === 0 && 'bg-gray-400'
                          }`}
                        >
                          <td>{vehicle.nom}</td>
                          <td>{vehicle.kilometrage}</td>
                          <td>
                            <Link
                              to={`/vehicles/${vehicle._id}`}
                              className="hover:text-stbSecond"
                            >
                              <EditIcon />
                            </Link>
                            <DeleteIcon
                              className="hover:text-stbSecond cursor-pointer"
                              onClick={() => {
                                deleteFromList(
                                  'vehicle',
                                  vehicle._id,
                                  vehicle.nom
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <Message
                          message={"Aucun véhicule n'a été trouvé"}
                          type="warning"
                          setError={setWarning}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="bg-gray-800 text-white justify-start flex rounded-b pl-2 py-2" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
