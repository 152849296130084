import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Message from '../components/Message';
import Pagination from '../components/Pagination';
import { Context } from '../components/Store';
import { deleteClient, getClients } from '../functions/client';

export default function Clients() {
  const [state] = useContext(Context);
  const [clients, setClients] = useState([]);
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [clientsByPage, setClientsByPage] = useState(
    localStorage.getItem('clientsByPage') || 20
  );

  const fetchClients = async (options = {}) => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const fetchedClients = await getClients(state.token, {
      limit: options.limit || clientsByPage,
      offset: options.offset || 0,
      filter: options.filter ?? text,
    });
    setIsLoading(false);
    if (fetchedClients.error) return setError(fetchedClients.error);
    setTotalCount(fetchedClients.totalCount);
    return setClients(fetchedClients.data);
  };

  useEffect(() => {
    fetchClients();
    if (localStorage.getItem('message')) {
      setInfo(localStorage.getItem('message'));
      localStorage.removeItem('message');
    }
  }, []); // eslint-disable-line

  const searchClients = () => {
    setInfo('');
    setError('');
    setWarning('');
    fetchClients();
  };

  const deleteFromList = async (id, enterprise) => {
    setError('');
    setInfo('');
    const res = window.prompt(
      `Confirmez la suppression du client ${enterprise} en renseignant le nom de l'entreprise ci-dessous. Cette action est définitive.`
    );
    if (res === enterprise) {
      setIsLoading(true);
      const deleted = await deleteClient(id, state.token);
      setIsLoading(false);
      if (deleted.error) return setError(deleted.error);
      fetchClients();
      setInfo(`Le client ${enterprise} a bien été supprimé.`);
    } else if (typeof res === 'string' || res > 0) {
      setError("Le nom de l'entreprise ne correspond pas.");
    }
  };

  function CustomPagination() {
    return (
      <Pagination
        page={page}
        setPage={setPage}
        fetchFunction={fetchClients}
        nbByPage={clientsByPage}
        setNbByPage={(nb) => {
          fetchClients({ limit: nb });
          localStorage.setItem('clientsByPage', nb);
          return setClientsByPage(nb);
        }}
        totalCount={totalCount}
      />
    );
  }

  document.title = 'Clients';

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1 className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8">
          Clients
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        <div className="bg-stbSecond rounded">
          <div className="bg-gray-800 text-white rounded-t flex items-center justify-between py-2 font-bold">
            <div className="flex w-1/3 mr-2" />
            <div className="flex w-1/3 justify-center">
              <h2>Liste des clients</h2>
            </div>
            <div className="flex w-1/3 mr-2 justify-end">
              <Link
                to="/clients/new"
                className="mr-8 border py-1 px-2 rounded border-white hover:bg-stbMain"
              >
                Nouveau client
              </Link>
            </div>
          </div>
          <div className="bg-gray-800 border-t border-stbSecond text-white justify-between flex px-2 py-2">
            <div className="ml-2 my-1 flex items-center">
              <form
                className="mr-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  searchClients();
                }}
              >
                <input
                  type="search"
                  id="searchText"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Rechercher un client..."
                  title="Filtrer par entreprise, numéro de téléphone, ou adresse"
                  className="w-full max-w-sm rounded pl-2 py-2 focus:outline-none text-black"
                />
              </form>
              <SearchIcon
                onClick={searchClients}
                className="cursor-pointer self-center ml-1 rounded hover:text-stbMain hover:bg-white"
              />
            </div>
            <CustomPagination />
          </div>
          <table className="w-full">
            <thead className="bg-stbMain text-white h-10 border-b-2 border-gray-800">
              <tr>
                <th>Entreprise</th>
                <th>Numéro de téléphone</th>
                <th>Adresse</th>
                <th>Contact</th>
                <th>Interventions liées</th>
                <th className="px-1">Options</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6">
                    <Message message="Chargement..." type="success" />
                  </td>
                </tr>
              ) : clients.length === 0 ? (
                <tr>
                  <td colSpan="6">
                    <Message
                      message={"Aucun client n'a été trouvé"}
                      type="warning"
                      setError={(_) => {
                        setWarning('');
                        setText('');
                        fetchClients({ filter: '' });
                      }}
                    />
                  </td>
                </tr>
              ) : (
                clients.map((client, i) => {
                  return (
                    <tr
                      key={client._id}
                      className={`hover:bg-stbMain hover:text-white ${
                        i % 2 === 0 && 'bg-gray-400'
                      }`}
                    >
                      <td>{client.enterprise}</td>
                      <td>{client.telephone}</td>
                      <td>{client.address}</td>
                      <td>{client.contact}</td>
                      <td>
                        <Link to={`/fiches/client/${client._id}`}>
                          <VisibilityIcon alt="Voir les interventions liées" />
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/clients/${client._id}`}
                          className="hover:text-stbSecond"
                        >
                          <EditIcon />
                        </Link>
                        <DeleteIcon
                          className="hover:text-stbSecond cursor-pointer"
                          onClick={() => {
                            deleteFromList(client._id, client.enterprise);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="bg-gray-800 text-white justify-between flex rounded-b p-2 py-2">
            <div />
            <CustomPagination />
          </div>
        </div>
      </div>
    </div>
  );
}
