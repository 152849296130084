const fetch = require('node-fetch');

export default async function fetchWithTimeout(url, options, delay = 10000) {
  try {
    const timer = new Promise((resolve) => {
      setTimeout(resolve, delay, {
        timeout: true,
      });
    });
    const response = await Promise.race([
      fetch(url, options).then((res) => {
        if (
          res.status === 204 ||
          (res.status === 200 &&
            parseInt(res.headers.get('content-length')) === 0)
        )
          return res;
        if (res.status >= 400) {
          return res.json().then((error) => {
            return { error: error.error, errorCode: res.status };
          });
        }
        return res.json();
      }),
      timer,
    ]);
    if (response.timeout) {
      console.error('Server Timeout');
      return { error: 'Server error, please check server status' };
    }
    return response;
  } catch (e) {
    return { error: `Problème de connexion : ${e}` };
  }
}
