import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ClientForm from '../pages/ClientForm';
import Clients from '../pages/Clients';
import FicheForm from '../pages/FicheForm';
import Gestion from '../pages/Gestion';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NewFiche from '../pages/NewFiche';
import NotFound from '../pages/NotFound';
import Pointages from '../pages/Pointages';
import PointagesByUser from '../pages/PointagesByUser';
import UserForm from '../pages/UserForm';
import VehicleForm from '../pages/VehicleForm';
import Navbar from './Navbar';
import { Context } from './Store';

export default function Router() {
  const [state, setState] = useContext(Context);
  const isLogged = (state.user && state.user.username) || false;

  function Logout() {
    if (localStorage.getItem('user')) localStorage.removeItem('user');
    if (localStorage.getItem('token')) localStorage.removeItem('token');
    setState({ user: {}, token: '' });
    return <Redirect to="/login" />;
  }

  function RedirectToHomePage() {
    return <Redirect to="/" />;
  }

  return (
    <BrowserRouter>
      {isLogged && <Navbar />}
      {isLogged ? (
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/fiches/new" exact component={NewFiche} />
          <Route path="/fiches/:id" exact component={FicheForm} />
          <Route path="/clients" exact component={Clients} />
          <Route
            path={['/clients/new', '/clients/:id']}
            exact
            component={ClientForm}
          />
          <Route path="/gestion" exact component={Gestion} />
          <Route
            path={['/users/new', '/users/:id']}
            exact
            component={UserForm}
          />
          <Route
            path={['/vehicles/new', '/vehicles/:id']}
            exact
            component={VehicleForm}
          />
          <Route path="/pointages" exact component={Pointages} />
          <Route path="/pointages/:userId" exact component={PointagesByUser} />
          <Route path="/login" component={RedirectToHomePage} />
          <Route path="/logout" component={Logout} />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Route component={Login} />
      )}
    </BrowserRouter>
  );
}
