import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Retourne tous les clients
 * @param {String} token Le token d'admin de l'user connecté
 * @returns array de tous les clients
 */
export async function getClients(
  token,
  { limit = 20, offset = 0, filter = '' }
) {
  const clients = await fetchWithTimeout(
    `${API_URL}clients?limit=${limit}&offset=${offset}${
      filter.length > 0 ? `&filter=${filter}` : ''
    }`,
    {
      headers: { 'Content-Type': 'application/json', 'auth-token': token },
    }
  );
  return clients;
}

/**
 * Retourne un client
 * @param {String} id L'identifiant du client
 * @param {String} token Le token d'admin de l'user connecté
 * @returns le client désiré, ou une erreur
 */
export async function getClient(id, token) {
  const client = await fetchWithTimeout(`${API_URL}clients/${id}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return client;
}

/**
 * Crée un nouveau client
 * @param {Object} body L'objet client avec les champs enterprise, telephone et address
 * @param {String} token Le token d'admin de l'user connecté
 * @returns Le nouveau client créé, ou une erreur
 */
export async function createClient(body, token) {
  const client = await fetchWithTimeout(`${API_URL}clients/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
    body: JSON.stringify(body),
  });
  return client;
}

/**
 * Modifie un client existant
 * @param {String} id L'identifiant du client
 * @param {Object} body L'objet client avec les champs enterprise, telephone et address
 * @param {String} token Le token d'admin de l'user connecté
 * @returns Le client mis à jour, ou une erreur
 */
export async function updateClient(id, body, token) {
  const client = await fetchWithTimeout(`${API_URL}clients/${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
    body: JSON.stringify(body),
  });
  return client;
}

/**
 * Supprime un client
 * @param {String} id L'identifiant du client
 * @param {String} token Le token d'admin de l'user connecté
 * @returns rien, ou une erreur
 */
export async function deleteClient(id, token) {
  const client = await fetchWithTimeout(`${API_URL}clients/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return client;
}
