import TableChart from '@material-ui/icons/TableChart';
import { CSVLink } from 'react-csv';

import { ficheFields } from '../utils/constants';

export function GenerateCsv({ fiche, fetchedData }) {
  const fields = [];
  // par rapport au tableau répertoriant tous les champs dans le fichier constants, on boucle sur le type de fiche
  Object.keys(ficheFields).map((typeFiche) => {
    // On choisit de ne sélectionner que les champs correspondants au type de la fiche fourni en paramètre
    if (
      parseInt(typeFiche) === 0 ||
      parseInt(typeFiche) === parseInt(fiche.type) ||
      (parseInt(fiche.type) === 2 &&
        parseInt(fiche.typeFicheDemontage) === parseInt(typeFiche) - 20) ||
      (parseInt(fiche.type) === 3 &&
        parseInt(fiche.typeFicheRemontage) === parseInt(typeFiche) - 30)
    ) {
      // On affiche l'entête du groupe de champs (informations générales, détails triphasé, etc...)
      fields.push([ficheFields[typeFiche].title, '']);
      fields.push(['', '']);
      // Pour tous les champs contenus dans ce groupe
      Object.keys(ficheFields[typeFiche].fields).map((f) => {
        const field = ficheFields[typeFiche].fields[f];
        // Pour la fiche de démontage de pompe, on n'utilise que les champs décrits ci-dessous
        if (
          parseInt(fiche.type) === 2 &&
          parseInt(fiche.typeFicheDemontage) === 1 &&
          parseInt(typeFiche) === 2 &&
          !['typeFicheDemontage', 'marque', 'typeMoteur', 'numSerie'].includes(
            f
          )
        ) {
          return false;
        }
        // Si le champ n'est pas rempli dans la fiche, on ne l'affiche pas
        if (
          (Array.isArray(fiche[f]) && fiche[f].length === 0) ||
          !fiche.hasOwnProperty(f) ||
          fiche[f] === ''
        )
          return false;
        switch (field.type) {
          case 'select':
            let val;
            if (field.values) {
              val = field.values.find(
                (v) => v.value.toString() === fiche[f]?.toString()
              )?.label;
            } else if (field.data) {
              if (field.multiple) {
                if (fiche[f]) {
                  val = fiche[f].map(
                    (v1) =>
                      fetchedData[field.data].find((v2) => v2.value === v1)
                        ?.label
                  );
                }
              } else {
                val = fetchedData[field.data].find(
                  (v) => v.value === fiche[f]
                )?.label;
              }
            }
            return fields.push([
              field.label,
              field.multiple ? val?.join(', ') : val,
            ]);
          case 'array':
            if (field.subtype === 'object') {
              return fiche[f]
                .map((v1) =>
                  Object.keys(field.values)
                    .map((v2) => {
                      return `${field.values[v2].label}: ${v1[v2]}`;
                    })
                    .join(', ')
                )
                .map((res) => fields.push([field.label, res]));
            }
            return fields.push([field.label, fiche[f].join(', ')]);

          case 'radio':
            return fields.push([
              field.label,
              field.options
                ? field.options.find((o) => o.value.toString() === fiche[f])
                    ?.label
                : fiche[f] === true || fiche[f] === 'true'
                ? 'Oui'
                : 'Non',
            ]);
          default:
            return fields.push([field.label, fiche[f]]);
        }
      });
      fields.push(['', '']);
      return fields.push(['', '']);
    }
    return false;
  });
  return (
    <CSVLink
      data={fields}
      filename={`${fiche.numFiche}.csv`}
      tag="_blank"
      className="flex items-center justify-center border py-1 px-2 rounded border-white hover:bg-stbMain cursor-pointer"
    >
      <div className="mr-2">Exporter les données au format CSV</div>
      <TableChart />
    </CSVLink>
  );
}
