import React from 'react';

import Router from './components/Router';
import Store from './components/Store';

function App() {
  return (
    <Store>
      <div style={{ textAlign: 'center' }}>
        <Router />
      </div>
    </Store>
  );
}

export default App;
