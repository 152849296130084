import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubmitButton } from '../components/Buttons';
import { ArrayInput, Select, TextFormField } from '../components/FormFields';
import { GenerateCsv } from '../components/GenerateCsv';
import { GeneratePdf } from '../components/GeneratePdf';
import Message from '../components/Message';
import { Context } from '../components/Store';
import { getClients } from '../functions/client';
import { getFiche, updateFiche } from '../functions/fiche';
import { getUsers } from '../functions/user';
import { getVehicles } from '../functions/vehicle';
import {
  ficheDemontageExceptPompeReducteurMonophase,
  ficheDemontageMonophase,
  ficheDemontageTriphase,
  ficheFields,
  ficheRemontageExceptPompeReducteurMonophase,
  ficheRemontageMonophase,
  ficheRemontageTriphase,
} from '../utils/constants';
import {
  formatDate,
  formatMillisToTime,
  formatTimeToMillis,
  sectionTotale,
} from '../utils/utils';

export default function FicheForm(props) {
  const [state] = useContext(Context);
  const history = useHistory();

  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [info, setInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [fiche, setFiche] = useState();
  const [fetchedData, setFetchedData] = useState(null);

  const submitForm = async () => {
    if (fiche.numDevis === '') return setError('Le numéro de devis est requis');
    if (fiche.numFiche === '') return setError('Le numéro de fiche est requis');
    setError('');
    const data = {
      ...fiche,
      ...(fiche.dateDebut &&
        fiche.dateDebut !== '' && {
          dateDebut: `${fiche.dateDebut}T12:00:00.003Z`,
        }),
      ...(fiche.dureeTotale &&
        fiche.dureeTotale !== '' && {
          dureeTotale: formatTimeToMillis(fiche.dureeTotale),
        }),
    };
    if (data._id) delete data._id;
    if (data.author) delete data.author;
    setIsLoading(true);
    const res = await updateFiche(props.match.params.id, data, state.token);
    setIsLoading(false);
    if (res.error) return setError(res.error);
    localStorage.setItem('message', `La fiche a bien été mise à jour.`);
    return history.push(
      `/?${new URLSearchParams(props.location.search).toString()}`
    );
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    let fetchedVehicles = await getVehicles(state.token);
    let fetchedUsers = await getUsers(state.token, { status: [1, 2, 3] });
    let fetchedClients = await getClients(state.token, { limit: 5000 });
    setIsLoading(false);
    if (fetchedVehicles.error) return setError(fetchedVehicles.error);
    if (fetchedUsers.error) return setError(fetchedUsers.error);
    if (fetchedClients.error) return setError(fetchedClients.error);
    if (fetchedClients.data.length === 0)
      return setError("Veuillez d'abord créer un client");
    fetchedVehicles = [
      { value: '', label: 'Aucun véhicule sélectionné.' },
      ...fetchedVehicles.data.map((v) => {
        return { value: v._id, label: v.nom };
      }),
    ];
    fetchedUsers = [
      { value: '', label: 'Aucun responsable sélectionné.' },
      ...fetchedUsers.data.map((u) => {
        return {
          value: u._id,
          label: `${u.prenom || u.username} ${u.nom || ''}`,
        };
      }),
    ];
    fetchedClients = fetchedClients.data.map((c) => {
      return { value: c._id, label: c.enterprise };
    });
    return setFetchedData({
      clients: fetchedClients,
      users: fetchedUsers,
      vehicles: fetchedVehicles,
    });
  }, [state.token]);

  const fetchFiche = useCallback(async () => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const fetchedFiche = await getFiche(state.token, props.match.params.id);
    setIsLoading(false);
    if (fetchedFiche.error) return setError(fetchedFiche.error);
    setFiche({
      ...fetchedFiche.data,
      ...(fetchedFiche.data.client && { client: fetchedFiche.data.client._id }),
      ...(fetchedFiche.data.resp && { resp: fetchedFiche.data.resp._id }),
      ...(fetchedFiche.data.author && {
        author: fetchedFiche.data.author.username,
      }),
      ...(fetchedFiche.data.techniciens && {
        techniciens: fetchedFiche.data.techniciens.map((u) => u),
      }),
      ...(fetchedFiche.data.dateDebut && {
        dateDebut: formatDate(fetchedFiche.data.dateDebut),
      }),
      ...{
        dureeTotale: formatMillisToTime(fetchedFiche.data?.dureeTotale || 0),
      },
    });
  }, [state.token, props.match.params.id]);

  useEffect(() => {
    fetchFiche();
    fetchData();
  }, [fetchFiche, fetchData]);
  document.title = "Révision d'une fiche";
  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <div className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8" />
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        <div className="bg-stbMain rounded">
          <div className="bg-gray-800 text-white rounded-t">
            <div className="flex items-center justify-between py-2 px-4">
              {fiche && fetchedData && (
                <div className="w-1/3">
                  <GenerateCsv fiche={fiche} fetchedData={fetchedData} />
                </div>
              )}
              <div className="flex w-1/3 justify-center">
                <h2 className="text-2xl font-bold">Révision d'une fiche</h2>
              </div>
              {fiche && fetchedData && (
                <div className="w-1/3">
                  <GeneratePdf
                    fiche={fiche}
                    fetchedData={fetchedData}
                    setError={setError}
                  />
                </div>
              )}
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
            className="px-4 py-3 text-white font-bold"
          >
            {fiche &&
              fetchedData &&
              Object.keys(ficheFields).map((typeFiche) => {
                return (
                  (parseInt(typeFiche) === 0 ||
                    parseInt(typeFiche) === parseInt(fiche.type) ||
                    (parseInt(fiche.type) === 2 &&
                      parseInt(fiche.subtype) === parseInt(typeFiche) - 20) ||
                    (parseInt(fiche.type) === 3 &&
                      parseInt(fiche.subtype) ===
                        parseInt(typeFiche) - 30)) && (
                    <fieldset
                      className="border-2 border-white pt-2 mb-4 rounded"
                      key={typeFiche}
                    >
                      <legend className="px-1 ml-1d text-xl">
                        {ficheFields[typeFiche].title}
                      </legend>
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        {Object.keys(ficheFields[typeFiche].fields).map(
                          (f, i) => {
                            const field = ficheFields[typeFiche].fields[f];

                            /* If Fiche Demontage / Remontage motopompe / motoreducteur */
                            if (
                              [2, 3].includes(parseInt(fiche.type)) &&
                              [7, 9].includes(parseInt(fiche.subtype)) &&
                              [27, 29, 37, 39].includes(parseInt(typeFiche))
                            ) {
                              /* If the field typeMotopompe/typeMotoreducteur is not set, we only show this field */
                              if (
                                !fiche.typeMotopompe &&
                                !fiche.typeMotoreducteur &&
                                ![
                                  'typeMotopompe',
                                  'typeMotoreducteur',
                                ].includes(f)
                              )
                                return false;

                              /* If the type is triphasé, we do not show fields of monophasé */
                              if (
                                (parseInt(fiche?.typeMotopompe) === 1 ||
                                  parseInt(fiche?.typeMotoreducteur) === 1) &&
                                (Object.keys(ficheDemontageMonophase).find(
                                  (k) => k === f
                                ) ||
                                  Object.keys(ficheRemontageMonophase).find(
                                    (k) => k === f
                                  ))
                              )
                                return false;

                              /* If the type is monophasé, we do not show fields of triphasé */
                              if (
                                (parseInt(fiche?.typeMotopompe) === 2 ||
                                  parseInt(fiche?.typeMotoreducteur) === 2) &&
                                (Object.keys(ficheDemontageTriphase).find(
                                  (k) => k === f
                                ) ||
                                  Object.keys(ficheRemontageTriphase).find(
                                    (k) => k === f
                                  ) ||
                                  Object.keys(
                                    ficheDemontageExceptPompeReducteurMonophase
                                  ).find((k) => k === f) ||
                                  Object.keys(
                                    ficheRemontageExceptPompeReducteurMonophase
                                  ).find((k) => k === f))
                              )
                                return false;
                            }

                            switch (field.type) {
                              case 'select':
                                return (
                                  <Select
                                    value={
                                      field.multiple
                                        ? fiche[f]
                                          ? fiche[f].map((a) => a.value || a)
                                          : []
                                        : fiche[f] || 0
                                    }
                                    setter={(newVal) =>
                                      setFiche({ ...fiche, [f]: newVal })
                                    }
                                    label={field.label}
                                    id={f}
                                    key={i}
                                    required={field.required}
                                    multiple={field.multiple}
                                    options={
                                      field.values
                                        ? [
                                            {
                                              value: '',
                                              label: 'Sélectionner...',
                                            },
                                            ...field.values,
                                          ]
                                        : field.multiple &&
                                          fetchedData &&
                                          fetchedData[field.data].length > 0
                                        ? fetchedData[field.data].slice(
                                            1,
                                            fetchedData[field.data].length
                                          )
                                        : fetchedData[field.data]
                                    }
                                  />
                                );
                              case 'array':
                                return (
                                  <ArrayInput
                                    values={fiche[f] || []}
                                    setter={(newVal) =>
                                      setFiche({ ...fiche, [f]: newVal })
                                    }
                                    label={
                                      f === 'sectionsFils' ? (
                                        <span className="font-thin">
                                          {field.label}
                                          <i>
                                            {` - Section totale : `}
                                            <span className="font-extrabold">{`${sectionTotale(
                                              fiche[f] || []
                                            )}`}</span>
                                            mm²
                                          </i>
                                        </span>
                                      ) : (
                                        field.label
                                      )
                                    }
                                    format={
                                      field?.subtype === 'object' &&
                                      field.values
                                    }
                                    options={
                                      field?.subtype === 'select' &&
                                      field.values
                                    }
                                    id={f}
                                    key={i}
                                    type={field?.subtype}
                                    numFiche={fiche.numFiche}
                                    setError={setError}
                                    setInfo={setInfo}
                                  />
                                );
                              default:
                                return (
                                  <TextFormField
                                    type={field.type || 'text'}
                                    value={fiche[f] ?? ''}
                                    setter={(newVal) =>
                                      setFiche({ ...fiche, [f]: newVal })
                                    }
                                    label={field.label}
                                    unit={field.unit}
                                    id={f}
                                    key={i}
                                    required={field.required}
                                    readonly={field.readonly}
                                    options={field.options || []}
                                    numFiche={fiche.numFiche}
                                    setError={setError}
                                    setInfo={setInfo}
                                    {...field}
                                  />
                                );
                            }
                          }
                        )}
                      </div>
                    </fieldset>
                  )
                );
              })}
          </form>
          <div className="bg-gray-800 text-white items-center justify-between flex rounded-b px-4 py-2">
            <div />
            {fiche && (
              <SubmitButton
                action={submitForm}
                label={`${
                  isLoading
                    ? 'Chargement...'
                    : 'Confirmer la révision de la fiche'
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
