export const formatDate = (dateString, printFr = false) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return printFr ? `${dt}/${month}/${year}` : `${year}-${month}-${dt}`;
};

/**
 * Converts milliseconds into js time format ("HH:mm")
 * @param {number} millis Milliseconds to convert into time
 */
export const formatMillisToTime = (millis) => {
  return new Date(millis).toISOString().substr(11, 8);
};

/**
 * Converts js time to milliseconds
 * @param {string} time Time format should be "HH:mm"
 */
export const formatTimeToMillis = (time) => {
  return new Date(`1970-01-01T${time}Z`).getTime();
};

export const sectionTotale = (values) => {
  let total = 0;
  values.map(
    (v) => (total += ((v.diametre * v.diametre * Math.PI) / 4) * v.nbBrins)
  );
  return Math.round(total * 10 ** 3) / 10 ** 3;
};

/**
 * Transform a field from a fiche into a string
 * @param {Fiche} fiche The fiche where to get data
 * @param {*} fetchedData Additional data such as techniciens, clients, vehicles
 * @param {Field} field The object containing the label of the field, the type...
 * @param {string} f The name of the field
 */
export const stringifyValue = (fiche, fetchedData, field, f) => {
  switch (field.type) {
    case 'select':
      let val;
      if (field.values) {
        val = field.values.find(
          (v) => v.value.toString() === fiche[f].toString()
        )?.label;
      } else if (field.data) {
        if (field.multiple) {
          if (fiche[f]) {
            val = fiche[f].map(
              (v1) =>
                fetchedData[field.data].find((v2) => v2.value === v1)?.label
            );
          }
        } else {
          val = fetchedData[field.data].find(
            (v) => v.value === fiche[f]
          )?.label;
        }
      }
      return field.multiple ? val?.join(', ') : val;
    case 'array':
      if (field.subtype === 'object') {
        return fiche[f]
          .map((v1) =>
            Object.keys(field.values)
              .map((v2) => `${field.values[v2].label}: ${v1[v2]}`)
              .join(', ')
          )
          .join(' | ');
      }
      return fiche[f].join(', ');

    case 'radio':
      return field.options
        ? field.options.find((o) => o.value.toString() === fiche[f].toString())
            ?.label
        : fiche[f] === true || fiche[f] === 'true'
        ? 'Oui'
        : 'Non';
    default:
      return fiche[f];
  }
};
