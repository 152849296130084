import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="flex flex-col items-center mt-10">
      <img src="/logo.png" alt="Logo STB" className="w-1/2 mb-14" />
      <h1 className="text-3xl mb-8">Erreur 404, la page n'existe pas.</h1>
      <Link to="/" className="text-stbMain italic text-xl font-extrabold">
        Revenir à l'accueil
      </Link>
    </div>
  );
}
