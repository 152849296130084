import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Calls route /pointages to fetch pointages that match the filters
 * @param {String} token The authentication token
 * @param {String} user Optional, the user ID to which filter the pointages
 * @param {String} minDate Optional, the minimum date to fetch the pointages (ISO format)
 * @param {String} maxDate Optional, the maximum date to fetch the pointages (ISO format)
 * @returns {Promise<{data: [{user: string, timestamp: String}], totalCount: number>} The array of pointages that match the filters
 */
export async function getPointages(
  token,
  user = null,
  minDate = null,
  maxDate = null
) {
  const params = new URLSearchParams();
  if (user) params.append('user', user);
  if (minDate) params.append('minDate', minDate);
  if (maxDate) params.append('maxDate', maxDate);
  params.append('limit', 250);
  return await fetchWithTimeout(`${API_URL}pointages?${params}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Calls route /pointages to create a new pointage
 * @param {String} token The authentication token
 * @param {String} user The user ID to which add the pointage
 * @param {String} timestamp The ISO-formatted timestamp, including the timezone
 * @returns {Promise<{data: {user: string, timestamp: String}}>} The new pointage created
 */
export async function createPointage(token, user, timestamp) {
  return await fetchWithTimeout(`${API_URL}pointages`, {
    method: 'POST',
    body: JSON.stringify({ user, timestamp }),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Calls route /pointages to update a pointage
 * @param {String} token The authentication token
 * @param {String} id The ID of the pointage to update
 * @param {String} timestamp The ISO-formatted timestamp, including the timezone
 * @returns {Promise<{data: {user: string, timestamp: String}}>} The updated pointage
 */
export async function patchPointage(token, id, timestamp) {
  return await fetchWithTimeout(`${API_URL}pointages/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({ timestamp }),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Calls route /pointages to delete a pointage
 * @param {String} token The authentication token
 * @param {String} id The ID of the pointage to delete
 */
export async function deletePointage(token, id) {
  return await fetchWithTimeout(`${API_URL}pointages/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}
