import React, { useState } from 'react';

const initialState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {},
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
};

export const Context = React.createContext();

function Store({ children }) {
  const [state, setState] = useState(initialState);
  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
}

export default Store;
