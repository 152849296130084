import Compressor from 'compressorjs';

/**
 * Compresses an image file to a target size in bytes.
 * If the image is already smaller than the target size, it will return the original file.
 * @param {File | Blob} file The file to compress
 * @param {number} targetSizeInBytes The target size in bytes (default: 2MB)
 * @returns {Promise<File | Blob>} The compressed file
 * @throws Error if the file is not an image
 */
const compressImage = async (file, targetSizeInBytes = 2000000) => {
  const qualityStep = 0.01;
  let min = 0;
  let max = 1;
  let mid, compressedFile;
  if (file.size < targetSizeInBytes) return file;
  // Binary search for the best quality (while the file is too big, decrease the quality)
  while (min <= max) {
    mid = (min + max) / 2;
    compressedFile = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: mid,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
    if (compressedFile.size > targetSizeInBytes) {
      max = mid - qualityStep;
    } else {
      break;
    }
  }
  return compressedFile;
};

export default compressImage;
