import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Retourne tous les véhicules du site
 * @param {String} token Le token d'admin de l'user connecté
 * @returns array de tous les véhicules
 */
export async function getVehicles(token) {
  const vehicles = await fetchWithTimeout(`${API_URL}vehicles/`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return vehicles;
}

/**
 * Retourne un véhicule avec son id
 * @param {String} id L'identifiant du véhicule
 * @param {String} token Le token d'admin de l'user connecté
 * @returns Object le véhicule demandé
 */
export async function getVehicle(id, token) {
  const vehicle = await fetchWithTimeout(`${API_URL}vehicles/${id}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return vehicle;
}

/**
 * Crée un nouveau véhicule
 * @param {Object} body Les informations du nouveau véhicule
 * @param {String} token Le token d'authentification de l'admin connecté
 * @returns Les informations du nouvel véhicule | Un message d'erreur si pas autorisé
 */
export async function createVehicle(body, token) {
  const post = await fetchWithTimeout(`${API_URL}vehicles/`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return post;
}

/**
 * Modifie le véhicule concerné
 * @param {String} id L'identifiant du véhicule à modifier
 * @param {Object} body Les nouvelles informations du véhicule
 * @param {String} token Le token d'authentification
 * @returns Les informations du véhicule concerné | Un message d'erreur si pas autorisé
 */
export async function editVehicle(id, body, token) {
  const patch = await fetchWithTimeout(`${API_URL}vehicles/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return patch;
}

/**
 * Supprime un véhicule
 * @param {String} id L'identifiant du véhicule à supprimer
 * @param {String} authToken Le token d'authentification
 * @returns Rien | Un message d'erreur si pas autorisé
 */
export async function deleteVehicle(id, token) {
  const vehicle = await fetchWithTimeout(`${API_URL}vehicles/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return vehicle;
}
