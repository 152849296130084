import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubmitButton } from '../components/Buttons';
import { Select, TextFormField } from '../components/FormFields';
import Message from '../components/Message';
import { Context } from '../components/Store';
import { getClients } from '../functions/client';
import { postFiche } from '../functions/fiche';
import { getUsers } from '../functions/user';
import { getVehicles } from '../functions/vehicle';
import { subTypeFicheValues, typeFicheList } from '../utils/constants';
import { formatDate } from '../utils/utils';

export default function NewFiche() {
  const [state] = useContext(Context);
  const history = useHistory();

  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [info, setInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [numDevis, setNumDevis] = useState('');
  const [numFiche, setNumFiche] = useState('');
  const [type, setType] = useState(1);
  const [subtype, setSubtype] = useState(6);
  const [client, setClient] = useState('');
  const [contact, setContact] = useState('');
  const [telContact, setTelContact] = useState('');
  const [dateDebut, setDateDebut] = useState(formatDate(new Date()));
  const [resp, setResp] = useState('');
  const [techniciens, setTechniciens] = useState([]);
  const [adresseChantier, setAdresseChantier] = useState('');
  const [vehicle, setVehicle] = useState('');

  const submitForm = async () => {
    if (numDevis === '') return setError('Le numéro de devis est requis');
    if (numFiche === '') return setError('Le numéro de fiche est requis');
    setError('');
    let data = {
      numDevis,
      numFiche,
      type,
      client,
      contact,
      ...(telContact !== '' && { telContact }),
      ...(dateDebut !== '' && { dateDebut: `${dateDebut}T12:00:00.003Z` }),
      ...(resp !== '' && { resp }),
    };
    switch (parseInt(type)) {
      case 1:
        data = {
          ...data,
          ...(techniciens.length > 0 && {
            techniciens:
              techniciens[0] === ''
                ? techniciens.slice(1, techniciens.length)
                : techniciens,
          }),
          adresseChantier,
          ...(vehicle !== '' && { vehicule: vehicle }),
        };
        break;
      case 2:
      case 3:
        data = { ...data, subtype };
        break;
      default:
        break;
    }
    setIsLoading(true);
    const res = await postFiche(data, state.token);
    setIsLoading(false);
    if (res.error) return setError(res.error);
    localStorage.setItem('message', `La fiche a bien été créée.`);
    return history.push(`/`);
  };

  const fetchClients = useCallback(async () => {
    setIsLoading(true);
    const fetchedClients = await getClients(state.token, { limit: 5000 });
    setIsLoading(false);
    if (fetchedClients.error) return setError(fetchedClients.error);
    if (fetchedClients.data.length === 0)
      return setError("Veuillez d'abord créer un client");
    setClient(fetchedClients.data[0]?._id || '');
    setAdresseChantier(fetchedClients.data[0]?.address || '');
    setContact(fetchedClients.data[0]?.contact || '');
    setTelContact(fetchedClients.data[0]?.telephone || '');
    return setClients(fetchedClients.data);
  }, [state.token]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    let fetchedUsers = await getUsers(state.token, { status: [1, 2, 3] });
    setIsLoading(false);
    if (fetchedUsers.error) return setError(fetchedUsers.error);
    fetchedUsers = [
      { _id: '', prenom: 'Aucun responsable sélectionné.' },
      ...fetchedUsers.data,
    ];
    setResp(fetchedUsers[0]?._id || '');
    return setUsers(fetchedUsers);
  }, [state.token]);

  const fetchVehicles = useCallback(async () => {
    setIsLoading(true);
    let fetchedVehicles = await getVehicles(state.token);
    setIsLoading(false);
    if (fetchedVehicles.error) return setError(fetchedVehicles.error);
    fetchedVehicles = [
      { _id: '', nom: 'Aucun véhicule sélectionné.' },
      ...fetchedVehicles.data,
    ];
    setVehicle(fetchedVehicles[0]?._id || '');
    return setVehicles(fetchedVehicles);
  }, [state.token]);

  useEffect(() => {
    fetchClients();
    fetchUsers();
    fetchVehicles();
  }, [fetchClients, fetchUsers, fetchVehicles]);

  document.title = 'Nouvelle fiche';
  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1
          className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8"
          id="big-title"
        >
          Nouvelle fiche
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        <div className="bg-stbMain rounded">
          <div className="bg-gray-800 text-white rounded-t">
            <div className="flex items-center justify-center py-2 font-bold">
              <div className="flex w-1/3 justify-center">
                <h2>Créer une fiche</h2>
              </div>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
            className="px-4 py-3 text-white font-bold grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
          >
            <TextFormField
              value={numDevis}
              setter={(newVal) => {
                setNumDevis(newVal);
                setNumFiche(
                  `${newVal.trim()}-${
                    numFiche.split('-').length > 1
                      ? numFiche.split('-')[1].trim()
                      : ''
                  }`
                );
              }}
              onBlur={() => setNumDevis(numDevis.trim())}
              label="Numéro de devis"
              id="numDevis"
              required
            />
            <TextFormField
              value={numFiche}
              setter={setNumFiche}
              onBlur={() => setNumFiche(numFiche.trim())}
              label="Numéro d'intervention"
              id="numFiche"
              required
            />
            <Select
              value={type}
              setter={setType}
              label="Type de fiche"
              id="type"
              options={typeFicheList}
              required
            />
            <Select
              value={client}
              setter={(v) => {
                const client = clients.find((c) => c._id === v);
                setAdresseChantier(client?.address || '');
                setContact(client?.contact || '');
                setTelContact(client?.telephone || '');
                return setClient(v);
              }}
              label="Client"
              id="client"
              options={clients.map((c) => {
                return { value: c._id, label: c.enterprise };
              })}
              required
            />
            <TextFormField
              value={contact}
              setter={setContact}
              label="Nom du contact"
              id="contact"
            />
            <TextFormField
              value={telContact}
              setter={setTelContact}
              label="Numéro de téléphone du contact"
              id="telContact"
            />
            <TextFormField
              value={dateDebut}
              setter={setDateDebut}
              label="Date de début"
              id="dateDebut"
              type="date"
            />
            <Select
              value={resp}
              setter={(newVal) => {
                setTechniciens([newVal]);
                setResp(newVal);
              }}
              label="Technicien responsable"
              id="resp"
              options={users.map((u) => {
                return {
                  value: u._id,
                  label: `${u.prenom || u.username} ${u.nom || ''}`,
                };
              })}
            />
            {(() => {
              switch (parseInt(type)) {
                case 1:
                  return (
                    <>
                      <TextFormField
                        value={adresseChantier}
                        setter={setAdresseChantier}
                        label="Adresse du chantier"
                        id="adresseChantier"
                      />
                      <Select
                        value={vehicle}
                        setter={setVehicle}
                        label="Véhicule utilisé"
                        id="vehicle"
                        options={vehicles.map((v) => {
                          return { value: v._id, label: `${v.nom}` };
                        })}
                      />
                      <Select
                        value={techniciens}
                        multiple
                        locked={resp}
                        setter={setTechniciens}
                        label="Techniciens présents"
                        id="techniciens"
                        options={users
                          .map((u) => {
                            return {
                              value: u._id,
                              label: `${u.prenom || u.username} ${u.nom || ''}`,
                            };
                          })
                          .filter((u) => u.value)}
                      />
                    </>
                  );
                case 2:
                  return (
                    <Select
                      value={subtype}
                      setter={setSubtype}
                      label="Type de fiche Démontage"
                      id="subtype"
                      options={subTypeFicheValues}
                    />
                  );
                case 3:
                  return (
                    <Select
                      value={subtype}
                      setter={setSubtype}
                      label="Type de fiche Remontage"
                      id="subtype"
                      options={subTypeFicheValues}
                    />
                  );
                default:
                  break;
              }
            })()}
          </form>
          <div className="bg-gray-800 text-white justify-end flex rounded-b px-4 py-2">
            <SubmitButton
              action={submitForm}
              label={`${
                isLoading ? 'Chargement...' : 'Créer une nouvelle fiche'
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
