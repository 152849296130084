import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Retourne toutes les fiches d'intervention du site
 * @param {String} token Le token d'admin de l'user connecté
 * @returns array de toutes les fiches
 */
export async function getFiches(
  token,
  {
    limit = 20,
    offset = 0,
    types = [],
    subtypes = [],
    status = [],
    filter = '',
  }
) {
  const fiches = await fetchWithTimeout(
    `${API_URL}fiches?limit=${limit}&offset=${offset}${
      filter.length > 0 ? `&filter=${filter}` : ''
    }${types.length > 0 ? `&type=${types.join('&type=')}` : ''}${
      subtypes.length > 0 ? `&subtype=${subtypes.join('&subtype=')}` : ''
    }${status.length > 0 ? `&status=${status.join('&status=')}` : ''}`,
    {
      headers: { 'Content-Type': 'application/json', 'auth-token': token },
    }
  );
  return fiches;
}

/**
 * Retourne une fiche d'intervention
 * @param {String} token Le token d'admin de l'user connecté
 * @param {String} id l'id de la fiche
 * @returns la fiche
 */
export async function getFiche(token, id) {
  return await fetchWithTimeout(`${API_URL}fiches/${id}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Met à jour le champ "status" des fiches à "archivé" (status = 5)
 * @param {Array} fiches La liste des fiches à archiver
 * @param {String} token Le token d'admin de l'user connecté
 * @returns array de toutes les fiches modifiées, une erreur sinon
 */
export async function editStatus(fiches, status, token) {
  const promises = fiches.map((fiche) =>
    fetchWithTimeout(`${API_URL}fiches/${fiche}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'auth-token': token },
      body: JSON.stringify({ status }),
    })
  );
  return await Promise.all(promises);
}

/**
 * Crée une nouvelle fiche
 * @param {Object} body le corps de la nouvelle fiche
 * @param {String} token Le token d'admin de l'user connecté
 * @returns la nouvelle fichée créée, une erreur sinon
 */
export async function postFiche(body, token) {
  const fiche = await fetchWithTimeout(`${API_URL}fiches/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
    body: JSON.stringify(body),
  });
  return fiche;
}

/**
 * Met à jour une fiche
 * @param {String} id l'identifiant de la fiche
 * @param {Object} body les nouvelles informations de la fiche
 * @param {String} token Le token d'admin de l'user connecté
 * @returns la fichée mise à jour, une erreur sinon
 */
export async function updateFiche(id, body, token) {
  const fiche = await fetchWithTimeout(`${API_URL}fiches/${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
    body: JSON.stringify(body),
  });
  return fiche;
}

/**
 * Supprime une fiche d'intervention
 * @param {String} token Le token d'admin de l'user connecté
 * @param {String} id l'id de la fiche
 */
export async function deleteFiche(id, token) {
  return await fetchWithTimeout(`${API_URL}fiches/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
}

/**
 * Supprime plusieurs fiches d'interventions
 * @param {Array} fiches La liste des id des fiches à supprimer
 * @param {String} token Le token d'admin de l'user connecté
 */
export async function deleteFiches(fiches, token) {
  const promises = fiches.map((fiche) => deleteFiche(fiche, token));
  return await Promise.all(promises);
}
